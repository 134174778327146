import apiInstance from ".";
import { CompanyCode, OfflinePromoCode } from "../global/constants";
import { readCookie } from "@nrg-storybook/reliant-component-library/lib/scripts/index";

const texasEnrollmentUrl = process.env.SIGNUP_TEXAS_HOST;

const isLocal = process.env.ENV === "LOCAL_DEV";

export const updateCookie = (
  c_name: string,
  value: string,
  exdays: number | null | undefined,
  domain = isLocal ? "localhost" : ".discountpowertx.com"
) => {
  const exdate = new Date(new Date().toISOString());
  if (exdays !== null && exdays !== undefined) {
    exdate.setDate(exdate.getDate() + exdays);
  }

  let c_value =
    encodeURIComponent(value) +
    (exdays == null ? "" : "; expires=" + exdate.toUTCString());

  c_value += `; domain=${domain};`;

  if (typeof document !== "undefined") {
    document.cookie = c_name + "=" + c_value;
  }
};

export const setCookie = (
  c_name: string,
  value: string,
  exdays: number | null | undefined,
  domain = isLocal ? "localhost" : ".discountpowertx.com"
) => {
  const exdate = new Date(new Date().toISOString());
  if (exdays !== null && exdays !== undefined) {
    exdate.setDate(exdate.getDate() + exdays);
  }
  let c_value =
    encodeURIComponent(value) +
    (exdays == null ? "" : "; expires=" + exdate.toUTCString());
  if (domain) {
    c_value += `; domain=${domain}`;
  }
  if (typeof document !== "undefined") {
    document.cookie = c_name + "=" + c_value;
  }
};

const searchServiceAddress = (
  query: string,
  premise: string,
  isTdsp: boolean = false
) => {
  if (isTdsp) {
    const payload = {
      servZipCode: query,
      companyCode: CompanyCode,
      affiliateId: "OE"
    };
    return apiInstance.post(`/api/v1/NRGREST/rest/sales/tdsp`, payload);
  } else {
    return apiInstance.get(
      `/api/v1/address?q=${query}&premise=${premise}&zipSearch=true`
    );
  }
};

const getPlanOffersByTdsp = (
  tdspCode: string,
  language: string,
  esiId?: string,
  isSapDown = false,
  promoCode: string | null | undefined = ""
) => {
  const promo = isSapDown ? OfflinePromoCode : promoCode;
  const promoParam = promo ? `&promoCode=${promo}` : "";
  const langParam = language === "en" ? "en-US" : "es-US";
  const esiParam = esiId ? `&esid=${esiId}` : "";
  const url = `/api/v1/offers?tdspCode=${tdspCode}${promoParam}&language=${langParam}&companyCode=${CompanyCode}${esiParam}`;
  return apiInstance.get(url);
};

const getFeatureFlags = () => {
  return apiInstance.get("/api/v1/feature-flag?key=health");
};

const getProductOffersAem = (uniqueProducts: any) => {
  if (uniqueProducts?.length > 1) {
    const url = apiInstance.get(
      (
        "api/aem/graphql/execute.json/nrg-aem-common/Products%3b" +
        uniqueProducts.map(
          (value: any, index: number) =>
            `id${index + 1}=${value?.product.aem_product_id}%3b`
        ) +
        "_locale=en"
      ).replace(/,/g, "")
    );
    return url;
  } else {
    return apiInstance.get(
      `api/aem/graphql/execute.json/nrg-aem-common/Product%3bproductID=${uniqueProducts[0]?.product?.aem_product_id}%3b_locale=en`
    );
  }
};

const getIncentivesAem = () => {
  return apiInstance.get(
    "api/aem/graphql/execute.json/nrg-aem-common/Incentive%3Blocale=en"
  );
};

const getGenericProductChartAem = (slugCode, stateCode, name) => {
  if (stateCode !== "" && name !== "") {
    return apiInstance.get(
      `api/aem/graphql/execute.json/nrg-aem-common/OfferPage%3Bcode=de-usn-${slugCode}-${stateCode.toLowerCase()}%3Blocale=en%3Bvariation=${name.toLowerCase()}`
    );
  } else {
    return apiInstance.get(
      `api/aem/graphql/execute.json/nrg-aem-common/OfferPage%3Bcode=de-usn-${slugCode}%3Blocale=en`
    );
  }
};
const getPageNotFoundHero = () => {
  return apiInstance.get(
    "api/aem/graphql/execute.json/nrg-aem-common/Hero%3Bcode=de-east-oam-404-hero"
  );
};

const getPageNotFoundColumns = () => {
  return apiInstance.get(
    "api/aem/graphql/execute.json/nrg-aem-common/ColumnContainerMediumPromo%3Bcode=de-east-oam-404-columns%3Blocale=en"
  );
};

const getContactCard404 = () => {
  return apiInstance.get(
    "api/aem/graphql/execute.json/nrg-aem-common/ColumnContainerMediumPromo;code=de-east-contact-card-container;locale=en"
  );
};

const getDisclaimerText = () => {
  const state = readCookie("de_state");
  return apiInstance.get(
    `/api/aem/graphql/execute.json/nrg-aem-common/MessagesByMultiCode%3Bcode1=de-east-oe-product-chart-footer${
      state === "IL" ? "%3Bvariation=de-east-product-chart-footer-illinois" : ""
    }`
  );
};

const addDECookies = (state: string, isUpdate = false) => {
  const lang: string = readCookie("language") ?? navigator.language;
  const cookiesData = [
    { key: "de_state", value: state },
    { key: "de_business", value: "Texas" },
    {
      key: "language",
      value: lang.toLowerCase().includes("es") ? "ES" : "EN"
    }
  ];

  cookiesData.forEach(d => {
    isUpdate
      ? updateCookie(
          d.key,
          d.value,
          90 // Expiration in 90 days
        )
      : setCookie(
          d.key,
          d.value,
          90 // Expiration in 90 days
        );
  });
};

const getIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    const ipAddress = data.ip;
    return ipAddress;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }

  return "";
};

const createEnrollment = async (planInfo, serviceDetails, address) => {
  const { tdspCode, referralId, affiliateId, state, language } = serviceDetails;
  try {
    const serviceAddress = {
      ...address,
      tdspCode
    };

    const ipAddress = await getIpAddress();

    const payload: Record<string, unknown> = {
      companyCode: CompanyCode,
      offerCode: planInfo.offerCode,
      promoCode: planInfo.promoCode,
      campaignCode: planInfo.campaignCode,
      offerCategory: planInfo.offerCategory,
      enrollmentAppUrl: texasEnrollmentUrl,
      langCode: language,
      postbackUrl: typeof window !== "undefined" ? window?.location.href : "",
      serviceAddress,
      ipAddress
    };

    if (referralId) {
      payload.referralId = referralId;
    }

    if (affiliateId) {
      payload.affiliateId = affiliateId;
    }

    const response = await apiInstance.post("/api/v1/enrollment", payload);
    return response.headers.location;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const validateReferralId = (referralId: string) => {
  return apiInstance.post("/api/v1/NRGREST/rest/sales/validateReferralId", {
    companyCode: CompanyCode,
    referralId
  });
};

const searchBusinessAddress = (query: string) => {
  return apiInstance.get(
    `/smartystreets/lookup?search=${query}&state_filter=tx&geolocate=true&geolocate_precision=city`
  );
};

const checkIpAddress = (ipAddressAllowed: string) => {
  return apiInstance.get(
    `/verify-ip-address?ipAddress=${ipAddressAllowed}&countryCheck=false`
  );
};

export const getProductChartFeatureFlag = (): any =>
  apiInstance.get("/api/feature-flag?key=PRODUCT_CHART_FLAG");

const DevelopmentApis = {
  searchServiceAddress,
  searchBusinessAddress,
  getPlanOffersByTdsp,
  getFeatureFlags,
  addDECookies,
  setCookie,
  updateCookie,
  checkIpAddress,
  createEnrollment,
  getProductOffersAem,
  getIncentivesAem,
  getGenericProductChartAem,
  validateReferralId,
  getPageNotFoundHero,
  getPageNotFoundColumns,
  getContactCard404,
  getDisclaimerText,
  getProductChartFeatureFlag
};

export default DevelopmentApis;
