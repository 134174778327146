export const CompanyCode = "0391";
export const ElecCommodity = "E";
export const GasCommodity = "G";
export const Promochannel = "382";
export const MarketSourceId = "6";
export const OfflinePromoCode = "WHPGB5";
export const PromoCode = "WHPGB5";
export const VivintPromoCode = "FRQFDZ";
export const isBrowser = typeof window !== "undefined";
export const PATHNAME = isBrowser ? window?.location.pathname : "";
export const COOKIE_DOMAIN_NAME = "domain=.directenergy.com";
export const AEM_IMAGE_URL = process.env.AEM_IMAGE_URL;
export const SOLAR_PANEL_CODE = "004013";
