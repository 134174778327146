const React = require("react");
const { CookiesProvider } = require("react-cookie");
const { I18nextProvider } = require("react-i18next");
const { ShopProvider } = require("./src/components/ShopContext");
const { i18nInit } = require("./src/i18n/config");
import NiceChat from "./src/components/NiceChat";
import "./src/pages/index.scss";

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18nInit}>
      <CookiesProvider>
        <ShopProvider>{element}</ShopProvider>
        <NiceChat />
      </CookiesProvider>
    </I18nextProvider>
  );
};
