import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { chatlaunch } from "@nrg-storybook/reliant-component-library/lib/scripts/chatlaunch";

const NiceChat = () => {
  const { i18n } = useTranslation();
  const chatChannel = process.env.CHATCHANNEL;
  const chatLanguage = i18n.language === "es" ? "Spanish" : "English";

  useEffect(() => {
    if (typeof window["cxone"] !== "undefined") {
      //window.cxone('guide', 'init', { locale: chatLanguage === "Spanish" ? "es_US" : "en_US"});
    } else {
      chatlaunch({
        channel: chatChannel,
        language: chatLanguage,
        applaunchsource: "web"
      });
    }
  }, [chatLanguage]);
  return <></>;
};

export default NiceChat;
