export const getLabelName = (text, value) => {
  const plainText = text?.replace(/%%.*%%/, value);
  return plainText;
};

export const getLabel = text => {
  const match = text.match(/%%(.*?)%%/);
  if (match) {
    const extractedString = match[1];
    return extractedString;
  }
};

export const getFirstLabel = text => {
  const plainText = text.split(/%%.*%%/);
  return plainText[0];
};

export const getUrlWithExistingParams = (
  newQueryParams: Record<string, string>,
  paramsToRemove: string[] = []
) => {
  const existingParams = new URLSearchParams(
    typeof location != "undefined" ? location.search : ""
  );

  Object.keys(newQueryParams || {}).forEach(paramKey => {
    existingParams.set(paramKey, newQueryParams[paramKey]);
  });

  paramsToRemove.forEach(paramToRemove => {
    existingParams.delete(paramToRemove);
  });

  const urlParams = existingParams.toString();

  return urlParams.length > 0 ? `?${urlParams}` : "";
};

export const getCookieValue = (key: string): string => {
  const cookies =
    typeof document !== "undefined" ? document.cookie.split(";") : [];
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${key}=`)) {
      const value = decodeURIComponent(cookie.substring(key.length + 1));
      return value;
    }
  }

  return "";
};

export const sortTerms = (
  items: {
    key: any;
    title: string | undefined;
    items: any;
  }[]
) => {
  const allTermsItem = items[0].items.find(
    (item: { label: string }) => item?.label === "All Terms"
  );
  const otherItems = items[0].items.filter(
    (item: { label: string }) => item?.label !== "All Terms"
  );

  otherItems.sort((a: { value: string }, b: { value: string }) => {
    return parseInt(a.value) - parseInt(b.value);
  });

  items[0].items = allTermsItem ? [allTermsItem, ...otherItems] : otherItems;

  return items;
};

export const getRateValue = (
  rateWithAutoPay: any,
  offer: any,
  rateUnit: any
) => {
  let rateValue: any;
  if (rateWithAutoPay && offer.autoPayDiscount) {
    rateValue = offer[`averageEFLPrice${rateUnit}`];
  } else if (offer.blockPrice) {
    rateValue = offer.blockPrice;
  } else {
    rateValue = offer[`averagePrice${rateUnit}`];
  }

  return rateValue;
};

export const getCharges = (offer: any) => {
  let predictablePlan = !(
    offer?.fixedMonthlyCharge == null ||
    ["", "0", "0.0", "0.00"].includes(offer?.fixedMonthlyCharge)
  );
  let chargesArray: any[] = [
    { value: "baseCharge", type: "base" },
    { value: "usageCharge", type: "usage" },
    { value: "tdspChargeText", type: "tdsp" },
    { value: "cancelFee", type: "cancel" }
  ]
    .map(({ type, value }) => {
      if (offer?.[value]) {
        return {
          type,
          value: `$${value}`
        };
      }
    })
    .filter(i => i);
  if (!predictablePlan && offer?.energyChargeText) {
    chargesArray.push({
      type: "energy",
      value: offer?.energyChargeText
    });
  }
  if (offer?.usageChargeThreshold) {
    chargesArray.push({
      type: "usage-threshold",
      value: `${offer?.usageChargeThreshold} kWh`
    });
  }

  return chargesArray;
};

export const getParameterByName = (name: string, url: string) => {
  name = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};
