import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import productChart from "./en/product-chart.json";
import tdspSelection from "./en/tdsp-selection.json";
import button from "./en/button.json";
import productChartES from "./es/product-chart.json";
import tdspSelectionES from "./es/tdsp-selection.json";
import buttonES from "./es/button.json";
import { readCookie } from "@nrg-storybook/reliant-component-library/lib/scripts/index";

export const resources = {
  en: {
    productChart,
    tdspSelection,
    button
  },
  es: {
    productChart: productChartES,
    tdspSelection: tdspSelectionES,
    button: buttonES
  }
} as const;

export const langs = ["en", "es"] as const;

const lang: string =
  typeof document != "undefined" ? readCookie("language") ?? "en" : "en";

i18n.use(initReactI18next).init({
  ns: ["productChart", "productChartES"],
  resources,
  lng: lang.toLowerCase(),
  fallbackLng: "en",
  supportedLngs: ["en", "es"],
  nonExplicitSupportedLngs: true,
  react: {
    useSuspense: true
  }
});

export { i18n as i18nInit };
