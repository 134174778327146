export interface ServiceAddress {
  addressText?: string;
  aptDesignator?: any;
  aptUnit?: any;
  city?: string;
  confidenceScore?: any;
  esiId?: string;
  meterType?: string;
  premiseType?: string;
  state: string;
  street?: string;
  tdsp?: Array<string>;
  tdspCodeCCS?: Array<string>;
  zipCode?: string;
  zip?: string;
  streetNumber?: any;
  streetName?: any;
}

export const serializeAddress = (
  value: ServiceAddress,
  isTdsp: boolean,
  userSelectedAddress?: any
): ServiceAddress => {
  const tdspValue = isTdsp ? value.tdspCodeCCS : value.tdsp;
  const tdspArray = Array.isArray(tdspValue)
    ? tdspValue.filter(Boolean)
    : [tdspValue].filter(Boolean);
  const currentTdsp = tdspArray.length > 1 ? tdspArray : tdspArray[0];

  return {
    ...value,
    esiId: value.esiId ?? userSelectedAddress?.esiId ?? "",
    streetName: value.street ?? userSelectedAddress?.street ?? "",
    aptDesignator:
      value.aptDesignator ?? userSelectedAddress?.aptDesignator ?? "",
    aptUnit: value.aptUnit ?? userSelectedAddress?.aptUnit ?? "",
    city: value.city ?? userSelectedAddress?.city ?? "",
    state: value?.state ?? userSelectedAddress?.state ?? "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tdsp: currentTdsp,
    zip: value.zipCode
  };
};
